//bg override
.bg-dark {
    background: var(--bg-dark) !important;
}
.bg-primary {
    background: var(--bg-primary);
    box-shadow: 0 0 2px 2px var(--light-border-color);
}
.bg-confirm-yes {
    background: var(--bg-green) !important;
}
.bg-confirm-no {
    background: var(--bg-red) !important;
}//end bg-overide
.bg-red {
    background-color: var(--bg-red);
}
.bg-blue {
    background-color: var(--bg-blue);
}
.bg-secondary {
    background-color: var(--bg-secondary) !important;
}
.border-secondary {
    border-color: var(--second-theme--color) !important;
}